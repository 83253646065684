import React from 'react'
import Layout from '../components/layout'
import SectionHeading from '../components/sectionHeading'
import { StaticImage } from 'gatsby-plugin-image'
import AreaCovered from "../components/areaCovered"
import ServiceCard from "../components/serviceCard"
import CompaniesSection from "../components/companiesSection"
import ExampleCard from "../components/exampleCard"
//import exampleMp4 from "../media/videos/Media864.mp4"
import WebsiteIntro from "../media/videos/WebsiteIntro.mp4"
import WebsiteIntroSmall from "../media/videos/WebsiteIntroSmall.mp4"
import testimonials from '../data/testimonials.js'
import TestimonialSlider from "../components/testimonialSlider"
import { SEO } from "../components/seo"
import "../styles/index.scss" 
/* import { graphql } from 'gatsby' */



const IndexPage = props => {
  return (
    <Layout>
  
    <div className="heroVideoContainer" id="hero-video-container">
      <div 
        dangerouslySetInnerHTML={{ __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            type="video/mp4" 
            class="heroVideo"
          >
            <source src="${WebsiteIntroSmall}" type="video/mp4" media="all and (max-width: 900px)"/>
            <source src="${WebsiteIntro}" type="video/mp4" />
          </video>,
        ` }}>
      </div>
      {/* <video className="heroVideo"
          autoPlay={"autoplay"}
          loop={"loop"}
          muted={"muted"}
          playsInLine={"playsinline"}
          type="video/mp4" >
        <source 
          src={exampleMp4} 
        />
      </video> */}
      <div className="heroVideoOverlay">
          <div className="heroVideoOverlayContents">
            We Make Beautiful Images So You Can Sell For The Best Price
            <a href="/booking">
                <button className="heroButton">
                    Book Now!
                </button>
            </a>
          </div> 
      </div>
    </div>
    <div className="mainContent">
      {/* <div className={swiperContainer}>
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          autoplay={{ delay: 5000 }}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true} pagination={{
            "clickable": true
          }} 
          navigation={true} 
          className={mySwiper}
        >
          <SwiperSlide>
            <StaticImage
              alt="Chris Kitchen"
              src="../media/images/chriskitchen.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              alt="Chris lounge"
              src="../media/images/lounge.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              alt="Chris bathroom"
              src="../media/images/bathroom.jpeg"
            />
          </SwiperSlide>
        </Swiper>
      </div> */}
        {/* <p>A slider of images (autoplay / swipe to change - can add text overlay)</p> */}
        
        
        <SectionHeading sectionHeadingTitle="Services" sectionHeadingText="REAL ESTATE MEDIA PACKAGES FOR UPSTATE SOUTH CAROLINA" sectionAnchor="examplesOfOurWorkAnchor"/>
        <div 
          className="examplesContainer">
          <ExampleCard className="exampleCard" image={<StaticImage
              className="serviceCardImage"
              alt="Photo example"
              src="../media/images/Photo.jpg"
            />} 
            title="Photography"
            text="We believe every listing deserves the highest quality photos."
            buttonText="See more"
            to="/images"
            /> 
          <ExampleCard className="exampleCard" image={<StaticImage
              className="serviceCardImage"
              alt="Video example"
              src="../media/images/Video.jpg"
              />} 
            title="Videography"
            text="If a picture paints a thousand words, imagine what a video can do."
            buttonText="See more"
            to="/videos"
          /> 
          <ExampleCard className="exampleCard" image={<StaticImage
              className="serviceCardImage"
              alt="Floor plan example"
              src="../media/images/FloorPlan.jpg"
              />} 
            title="Floor Plans"
            text="Help buyers understand how the house works for them."
            buttonText="See more"
            to="/floorplans"
            />           
        </div> 
        <SectionHeading sectionHeadingTitle="PACKAGES" sectionHeadingText="CHOOSE ONE OF OUR AMAZING PACKAGES" sectionAnchor="listingPackagesAnchor"/>
        <div
          className="servicesContainer"
        >
          <ServiceCard className="serviceCard bronze" 
              title= "GOOD"
              text= "Photography <br/> Property Page <br/> Floor Plan"
              price="250"
              medal= "bronze"
              buttonText="Book Now"
            /> 
          <ServiceCard className="serviceCard silver"
              title= "BETTER"
              text= "Photography <br/> Property Page <br/> Floor Plan <br/> Drone Photos"
              price= "275" 
              medal= "silver"
              buttonText="Book Now"/> 
          <ServiceCard className="serviceCard gold"
              title="BEST"
              text= "Photography <br/> Property Page <br/> Floor Plan <br/> Drone Photos <br/> Video"
              price="450"
              medal= "gold"
              buttonText="Book Now"/>
        </div>
        <SectionHeading sectionHeadingTitle="OTHER OPTIONS" sectionHeadingText="" sectionAnchor="listingPackagesAnchor2"/>
        <div
          className="servicesContainer otherOptions"
        >
          <ServiceCard 
              title="DRONE SHOOT <br/> &nbsp;"
              text="Drone Photos <br/> Property Page"
              price="175"
              medal= "basic"
              buttonText="Book Now"/>
          <ServiceCard 
              title="VIDEO <br/> &nbsp;"
              text="Video <br/> (Drone & Interior) <br/> Property Page"
              price="250"
              medal= "basic"
              buttonText="Book Now"/>
            <ServiceCard 
              title="ZILLOW <br/> 360 TOUR"
              text="Zillow 360 Tour <br/> Zillow Floor Plan"
              price="200"
              medal= "basic"
              buttonText="Book Now"/>
        </div> 
        <div className="testimonialsContainer"
          data-sal="slide-up"
          data-sal-delay="1200"
          data-sal-easing="ease"
          data-sal-duration="1200"
        >
          <SectionHeading sectionHeadingTitle="TESTIMONIALS" sectionHeadingText="WHAT OUR CLIENTS SAY" sectionAnchor="testimonialAnchor"/>
          <TestimonialSlider testimonials={testimonials}/>
        </div>
        <SectionHeading sectionHeadingTitle="" sectionHeadingText="COMPANIES WHO PUT THEIR FAITH IN US" sectionAnchor="companiesWhoUse"/>
        <CompaniesSection 
              image1={<StaticImage
              className="companyLogo"
              alt="Company Logo"
              src="../media/images/companylogos/reCompaines1.jpg"
            />} 
              image2={<StaticImage
              className="companyLogo"
              alt="Company Logo"
              src="../media/images/companylogos/reCompaines2.jpg"
            />} 
              image3={<StaticImage
              className="companyLogo"
              alt="Company Logo"
              src="../media/images/companylogos/reCompaines3.jpg"
            />} 
              image4={<StaticImage
              className="companyLogo"
              alt="Company Logo"
              src="../media/images/companylogos/reCompaines4.jpg"
            />} 
              image5={<StaticImage
              className="companyLogo"
              alt="Company Logo"
              src="../media/images/companylogos/reCompaines5.jpg"
            />} 
              image6={<StaticImage
              className="companyLogo"
              alt="Company Logo"
              src="../media/images/companylogos/reCompaines6.jpg"
            />} 
              image7={<StaticImage
              className="companyLogo"
              alt="Company Logo"
              src="../media/images/companylogos/reCompaines7.jpg"
            />} 
          />
        <SectionHeading sectionHeadingTitle="Area Covered" sectionHeadingText="WE ARE FOCUSED ON SERVING OUR LOCAL COMMUNITY" sectionAnchor="areaCoveredAnchor"/>
        <div
          data-sal="slide-up"
          data-sal-delay="1200"
          data-sal-easing="ease"
          data-sal-duration="1200"
        >
          <AreaCovered />
        </div>
    </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Home Page" />
)
export default IndexPage;

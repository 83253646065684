import * as React from 'react'
import * as servicesCSS from './services.module.css'
import { Link } from "gatsby"


const ServiceCard = ({title, text, fullprice=null, price, image, medal, buttonText}) => {
    if(fullprice){
        fullprice = <strike>${fullprice}</strike>;
    }
   var medalClass = "serviceCard " + medal;
  return (
    <div 
        className={medalClass}
        data-sal="slide-up"
        data-sal-delay="1200"
        data-sal-easing="ease"
        data-sal-duration="1200"
    >
        <div className="serviceTitle">
            <div dangerouslySetInnerHTML={{__html: title}} />
        </div>
        <div className="serviceText">
            <div dangerouslySetInnerHTML={{__html: text}} />
        </div>
        <div className="servicePrice">
            From {fullprice} <b>${price}</b> 
        </div>
        <div className="serviceButtonDiv">
            <Link to="/booking">
                <button className="serviceButton">
                        {buttonText}
                </button>
            </Link>

        </div>
    </div>
  )
}

export default ServiceCard
